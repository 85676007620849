<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h2>Halaman Dashboard</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  data() {
    return {
      currentUser: getUser(),
      dashboard: false,
    };
  },
  methods: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
};
</script>
